import { createEntityAdapter, createSelector, createSlice} from "@reduxjs/toolkit";
import apiClient from "../../../auth/apiClient";
import { BuildTree, findEntityForTree, findNearestFacilityFromEntity} from "./CoreEntityUtil";
import * as c from "../../../constants/";
import {
    changeNewFacilities,
    deleteEntity,
    fillEntityLevel,
    addNewEntities,
    addPermissionsToEntity,
    updateEntitySettings,
    deleteEntitySettings,
    updateEntity,
    onboardNewFacility,
    onboardValetArea,
    createNewEntity,
    setSetting,
    setSettings
} from "./CoreEntityThunks"

const entityAdapter = createEntityAdapter({
    selectId: (entity) => entity.entityid,
});

// Slice
const slice = createSlice({
    name: "coreEntities",
    initialState: entityAdapter.getInitialState({
        ContextID: null, //adding ContextID to the initial state
    }), // Initialize with adapter state
    reducers: {
        setEntities:(state, action) => {
            const { entityID, entities } = action.payload;

            state.ContextID = entityID;

            const updatedEntities = entities.map((entity) => ({
                ...entity,
                context: true, // Mark all loaded entities as part of the context
            }));

            entityAdapter.setAll(state, updatedEntities);
        },
        setContext: (state, action) => {
            const { contextID, entitiesToAdd } = action.payload;
            state.ContextID = contextID;

            Object.values(state.entities).forEach((entity) => {
                entity.context = false;
            });
            const updatedEntities = entitiesToAdd.map((entity) => ({
                ...entity,
                context: true,
            }));

            try {
                entityAdapter.upsertMany(state, updatedEntities);
            } catch (error) {
                console.error('Failed to update entities:', error);
            }
        },
        setEntityProperty:(state, action) => {
            const { entityId, property, value } = action.payload;
            const foundEntity = state.entities[entityId]

            if(foundEntity){
                foundEntity[property] = value
            }
        },
        setEntityStateIfOffline: (state, action) => {
            const { entityid, value } = action.payload;
            const foundEntity = state.entities[entityid]

            if(foundEntity && !foundEntity.state){
                foundEntity.state = value;
            }
        },
        setEntitiesProperty: (state, action) => {
            const {entities} = action.payload;
            entities.map(e => {
                const {entityid, property, value} = e;
                const foundEntity = state.entities[entityid]
                if (foundEntity) {
                    foundEntity[property] = value;
                }
            })
        },
        setSetting: (state, action) => {
            const { entityid, settingName, settingValue } = action.payload;
            const foundEntity = state.entities[entityid]
            if (!foundEntity) return;
            const setting = foundEntity.settings?.find(
                (x) => x.name.toLowerCase() === settingName.toLowerCase()
            );
            if (!setting) return;
            setting.value = settingValue;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(deleteEntity.fulfilled, (state, action) => {
                const { entityid } = action.payload.payload; // verify
                entityAdapter.removeOne(state,entityid);
            })
            .addCase(updateEntity.fulfilled, (state, action) => {
                const updatedEntity = action.payload;
                const entityId = updatedEntity.entityid || updatedEntity.entityid;

                if (!entityId) {
                    console.error("No valid entity ID found in the payload:", updatedEntity);
                    return;
                }

                const existingEntity = state.entities[entityId];

                const mergedEntity = {
                    ...existingEntity,
                    ...updatedEntity,
                    details: {
                        ...existingEntity?.details,
                        ...updatedEntity?.details,
                    },
                    settings: updatedEntity.settings || existingEntity?.settings,
                };

                entityAdapter.upsertOne(state, {
                    id: entityId,
                    changes: mergedEntity,
                });
            })
            .addCase(onboardNewFacility.fulfilled, (state, action) => {
                let facilityMetaData = {
                    entityid: action.payload.facilityID,
                    parententityid: action.payload.parentEntityID,
                    entitytype: c.ENTITY_TYPE.Facility,
                    children: [],
                    show: false,
                    context: false
                };
                entityAdapter.upsertOne(state, facilityMetaData)
            })
            .addCase(onboardValetArea.fulfilled, (state, action) => {
                let valetareaMetaData = {
                    entityid: action.payload.valetareaID,
                    parententityid: action.payload.parentEntityID,
                    entitytype: c.ENTITY_TYPE.ValetArea,
                    children: [],
                    show: false, // what does this do?
                    context: false
                };
                entityAdapter.upsertOne(state,valetareaMetaData)
            })
            .addCase(createNewEntity.fulfilled, (state, action) => {
                const updatedPayload = {
                    ...action.payload,
                    context: false
                };
                entityAdapter.addOne(state,updatedPayload)
            })
            .addCase(updateEntitySettings.fulfilled, (state, action) => {
                // this isn't doing anything in both slices SO WHY DOES THIS EVEN EXIST!?!?
            })
            .addCase(deleteEntitySettings.fulfilled, (state, action) => {
                const { entityId, settingName } = action.meta.arg;

                const entity = state.entities[entityId];

                if (entity && entity.settings) {
                    // Filter out the setting with the specified name
                    entity.settings = entity.settings.filter(
                        (setting) => setting.name !== settingName
                    );
                }
            })
            .addCase(addPermissionsToEntity.fulfilled, (state, action) => {
                entityAdapter.upsertOne(state,action.payload)
            })
            .addCase(changeNewFacilities.fulfilled, (state, action) => {
                const { contextID , contextPermissions , entitiesToAdd } = action.payload;
                if(!contextID) return;

                entitiesToAdd.forEach((entity) => {
                    if(entity.entityid === contextID){
                        entity.permissions =       [
                            ...contextPermissions[0].permissions?.map(
                                (permission) => permission.permissionName
                            ),
                            ...contextPermissions[0].groups
                                ?.map((group) => [...group.permissions])
                                .flat(),
                        ] ?? [];
                    }
                })

                Object.values(state.entities).forEach((entity) => {
                    entity.context = false;
                });

                entitiesToAdd.forEach((entity) => {
                    entity.context = true;
                });

                state.ContextID = contextID;

                const filteredEntities = Object.values(entitiesToAdd).filter(entity => entity !== undefined);
                entityAdapter.upsertMany(state,filteredEntities)

            })
            .addCase(addNewEntities.fulfilled, (state, action) => {
                const { newEntitiesToAdd } = action.payload;
                entityAdapter.upsertMany(state, newEntitiesToAdd)
            })
            .addCase(fillEntityLevel.fulfilled, (state, action) => {
                const { payload } = action;
                if(!payload){
                    return
                }

                let parentEntity = state.entities[payload.parentID]
                if(!parentEntity && payload.parentID != null){
                    entityAdapter.upsertMany(state,payload.built)
                }else if(!parentEntity){
                    // stop traversal because we either are at the top, or this truly is an unknown node
                    return
                }
            })
            .addCase(setSettings.fulfilled, (state, action) => {
                const updatedSettings = action.payload;
                updatedSettings.forEach((entity)=>{
                    entityAdapter.upsertOne(state,entity)
                })

            })
            .addCase(setSetting.fulfilled, (state, action) => {
                entityAdapter.upsertOne(state, action.payload)
            })
    },
});

// globalSelectors
const entitySelectors = entityAdapter.getSelectors((state) => state.coreEntities);

export const {
    selectAll: selectAllEntities, //Retrieve all entities as an array
    selectById: selectEntityById, // Retrieve a single entity by its ID
} = entitySelectors;

//Get entity of contextID
export const selectContextEntity = createSelector(
    (state) => state.coreEntities,
    (entities) => {
        const contextEntity = entities.entities[entities.ContextID];

        if (!contextEntity) {
            return null; // Return null if the context entity is not found
        }

        // Recursive function to find all children and their descendants
        const findChildren = (parentId) => {
            const directChildren = Object.values(entities.entities)
                .filter((entity) => entity.parententityid === parentId)
                .map(({ state, ...rest }) => rest); // Exclude the `state` field

            return directChildren.map((child) => ({
                ...child,
                children: findChildren(child.entityid), // Recursively find children for each child
            }));
        };

        return {
            ...contextEntity,
            children: findChildren(entities.ContextID), // Recursively find all children and descendants
        };
    }
);

export const selectContextEntityPermissions = createSelector(
    (state) => state.coreEntities,
    (entities) => {
        return entities.entities[entities.ContextID]?.permissions
    }
);

export const selectContextEntityName = createSelector(
    (state) => state.coreEntities,
    (entities) => {
        return entities.entities[entities.ContextID]?.name
    }
);

//Replaces the selectAllFacilities function
export const selectAllFacilities = createSelector(
    selectAllEntities,
    (entities) => entities.filter((entity) => entity.type === "Facility")
)


//replaces the BuildTree Function
export const selectEntityTree = createSelector(
    (state) => state.coreEntities,
    (entities) => BuildTree(entities)
);

// Update to proper camelCase after deprecation
export const selectRawIDsWithScope = createSelector(
    selectAllEntities,
    (entities) => {
        return entities.map((entity) => ({
            entityid: entity.entityid,
            parententityid: entity.parententityid || null, // Return null if no parentEntityId
        }));
    }
);

//Only used for the Overview page DON'T USE THIS
export const selectEntityForTree = (entityId) => createSelector(
    (state) => state.coreEntities.entities,
    (entities) => findEntityForTree(entities, entityId)
);

export const selectAllScopedPermissions = createSelector(
    (state) => state.coreEntities, // Access the normalized state slice
    (coreEntities) => {
        const result = {};
        // Iterate through the IDs to access each entity
        coreEntities.ids.forEach((id) => {
            const entity = coreEntities.entities[id];
            if (entity && entity.permissions && entity.permissions.length > 0) {
                result[id] = entity.permissions
            }
        });
        return result;
    }
);

export const selectAllEntitySettings = createSelector(
    (state) => state.coreEntities, // Access the normalized state slice
    (coreEntities) => {
        const result = [];

        // Iterate through the IDs to access each entity
        coreEntities.ids.forEach((id) => {
            const entity = coreEntities.entities[id];
            if (entity && entity.settings && entity.settings.length > 0) {
                result.push({
                    entityID: id,
                    settings: entity.settings
                })
            }
        });

        return result;
    }
);

// @description Gets entities with no state field useful GetEntityTreeWithNoState
// if you need everything in context but don't want it to re-render everytime a DSD comes in
export const selectEntitiesWithNoState = createSelector(
    (state) => state.coreEntities, // Access normalized entities
    (entities) => {
        const result = {};
        for (const [key, entity] of Object.entries(entities.entities)) {
            result[key] = {
                entityId: entity.entityid,
                parentEntityId: entity.parententityid || null,
                typeId: entity.typeid,
            };
        }
        return result;
    }
);


export const createNearestFacilityFromEntity = (entityId) =>
    createSelector(
        (state) => state.coreEntities, // Access the coreEntities slice
        ({entities}) => findNearestFacilityFromEntity(entities, entityId)
    );

//wrapper function for selectNearestFacilityGroup
export const selectNearestFacilityFromEntity = (entityId) => (state) => createNearestFacilityFromEntity(entityId)(state);

export const selectAllDeviceIds = createSelector(
    (state) => state.coreEntities, // Access the coreEntities slice
    ({ entities, ids }) => {
        return ids
            .map((id) => entities[id])
            .filter((entity) => entity?.typeid === c.ENTITY_TYPE.Device)
            .map((entity) => entity.entityid);
    }
);

export const makeParentEntitySelector = (entityId) =>
    createSelector(
        (state) => state.coreEntities, // Access the coreEntities slice
        ({ entities }) => {
            const parentEntityId = entities[entityId]?.parententityid;
            return entities[parentEntityId] ?? {};
        }
    );

export const selectParentEntity = (entityId) => (state) => makeParentEntitySelector(entityId)(state);

export const loadCoreEntities = (entityID) => async (dispatch) => {
    apiClient
        .get(`entities/${entityID}/tree`)
        .then((resp) => {
            dispatch(setEntities({ entityID, entities: resp.data })); //load the full entity tree into EntityList
        })
        .catch((err) => {
            console.error(
                "Encountered error while loading all entities from context:",
                err
            );
        })
        .finally(() => {
            //do nothing, getting IDs next
        });
};

export const {
    setEntities,
    setContext,
    setEntityProperty,
    setEntityStateIfOffline,
    setEntitiesProperty,
} = slice.actions;

export default slice.reducer;
