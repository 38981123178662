class InventoryService {
	constructor(client) {
		this.apiClient = client;
	}

	async getInventorySearch(entityID, entityIDs, facilityGroupId, type, maxEntranceTime, searchTerm, searchContext, limit, offset, cancelToken) {
		return this.apiClient.get(`inventory/search/${entityID}`, {
			params: {
				EntityIDs: entityIDs,
				FacilityGroupID: facilityGroupId,
				SearchContext: searchContext,
				Type: type,
				MaxEntranceTime: maxEntranceTime,
				SearchTerm: searchTerm || undefined,
				Limit: limit ?? 10,
				Offset: offset ?? 0
			}
		}, cancelToken);
	}
	

	async getTicketDetails(credentialId, cancelToken) {
		return this.apiClient.get(`inventory/details/ticket/${credentialId}`, cancelToken);
	}

	async getTransactionHistory(transactionId, cancelToken) {
		return this.apiClient.get(`inventory/history/${transactionId}`, cancelToken);
	}

	async updateTicketStatus(credentialId, status, reason, cancelToken) {
		return this.apiClient.put(`inventory/update/ticket`, { credentialId: credentialId, status: status, reason: reason }, {
			headers: { "Content-Type": "application/json" },
		}, cancelToken);
	}

  async getPrepaidDetails(accessHolderID, cancelToken) {
    return this.apiClient.get(`inventory/details/pass/${accessHolderID}`, cancelToken);
  }
	
  async getAccessHolderDetails(accessHolderID, cancelToken) {
    return this.apiClient.get(`inventory/details/accessholder/${accessHolderID}`, cancelToken);
  }

  async getValetInventoryData(entityID, offset, limit, cancelToken) {
    return this.apiClient.get(`valetinventory/${entityID}/${offset}/${limit}`, cancelToken);
  }	
  
  async getValetTicketDetails(transactionID, cancelToken) {
	return this.apiClient.get(`valetinventory/details/${transactionID}`, cancelToken);
  }

  async getValetTransactionHistory(transactionId, cancelToken) {
	return this.apiClient.get(`valetinventory/history/${transactionId}`, cancelToken);
}
}



export default InventoryService;