import React, {useEffect, useState} from "react";
import clsx from "clsx";
import PropTypes from "prop-types";


import {Typography, Container} from "@material-ui/core"
import Skeleton from "@material-ui/lab/Skeleton";
import {useStyles} from "./TicketDetails.styles";
import DetailsValueRow from "../../../Credentials/CredentialInventory/CredentialInventoryDetailDrawer/DetailsValueRow"
import useCancellationToken from "../../../../hooks/useCancellationToken";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import InventoryService from "../../../../services/InventoryService";

import apiClient from "../../../../auth/apiClient";

const TicketDetails = ({credentialId}) => {
  const classes = useStyles();
	const enqueueSnackbar = useEnqueueSnackbar();
	const inventoryService = new InventoryService(apiClient);
  const [loading, setLoading] = useState(false);
  const [ticketDetails, setTicketDetails] = useState(null);

	useEffect(() => {
    if (credentialId) {
      executeDetailsQuery();
    }
  }, [credentialId]);

  const getDetails = async ({cancelToken}) => {
		setLoading(true);
		const res = await inventoryService.getValetTicketDetails(credentialId, cancelToken);
    setLoading(false);
		setTicketDetails(res.data);
  }

  const {execute: executeDetailsQuery} = useCancellationToken({
    func: getDetails,
    errHandleFunc: () => {
      setLoading(false);
      enqueueSnackbar("Failed to load details", {
        variant: "error",
        tag: "FailedToLoadDetails",
      });
    },
  });


  return <>
    {loading ?
      <Container>
        <Skeleton className={classes.skeleton} variant="rect" height="2rem" animation="wave"/>
        <Skeleton className={classes.skeleton} variant="rect" height="2rem" animation="wave"/>
        <Skeleton className={classes.skeleton} variant="rect" height="2rem" animation="wave"/>
      </Container>
      :
      <>
        {ticketDetails !== null && (
          <>
            <DetailsValueRow title="Status">
            <Typography
									variant="h6"
									className={clsx(classes.centered)}
									data-value={"status"}>
										{"IN"}
								</Typography>
            </DetailsValueRow>
            <DetailsValueRow title="Ticket" thin={true}>
              <Typography>{ticketDetails?.ticketNumber}
                </Typography>
            </DetailsValueRow>
            <DetailsValueRow title="Plate" thin={true}>
              <Typography>{ticketDetails?.licensePlate}
                </Typography>
            </DetailsValueRow>
            <DetailsValueRow title="Make" thin={true}>
              <Typography>{ticketDetails?.make}
                </Typography>
            </DetailsValueRow>
            <DetailsValueRow title="Model" thin={true}>
              <Typography>{ticketDetails?.model}
                </Typography>
            </DetailsValueRow>
            <DetailsValueRow title="Color" thin={true}>
              <Typography>{ticketDetails?.color}
                </Typography>
            </DetailsValueRow>
            <DetailsValueRow title="Guest Type" thin={true}>
              <Typography>{ticketDetails?.guestType}
                </Typography>
            </DetailsValueRow>
            <DetailsValueRow title="Rate" thin={true}>
              <Typography>{ticketDetails?.rate}
                </Typography>
            </DetailsValueRow>
            </>
        )}
      </>
    }
  </>
    ;
};

TicketDetails.defaultProps = {
  credentialId: 0
};
TicketDetails.propTypes = {
  credentialId: PropTypes.number
};

export default TicketDetails;
