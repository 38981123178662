import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import moment from "moment-timezone";

import {useStyles} from "./HistoryComponent.styles";
import SquareChip from "../../../SquareChip";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails, Container
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Skeleton from "@material-ui/lab/Skeleton";

import useCancellationToken from "../../../../hooks/useCancellationToken";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import useCurrentFacilityTimezone from "../../../../hooks/useCurrentFacilityTimezone";

import InventoryService from "../../../../services/InventoryService";
import apiClient from "../../../../auth/apiClient";

const HistoryComponent = ({credentialId}) => {
  const classes = useStyles();
  const enqueueSnackbar = useEnqueueSnackbar();
  const inventoryService = new InventoryService(apiClient);
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState(null);
  const [historyOpen, setHistoryOpen] = useState(false);
  const {timeZone} = useCurrentFacilityTimezone();
  const getHistory = async ({cancelToken}) => {
    setLoading(true);
    const res = await inventoryService.getValetTransactionHistory(credentialId, cancelToken);
    setLoading(false);
    setHistory(res.data);
  }

  const {execute: executeHistoryQuery} = useCancellationToken({
    func: getHistory,
    errHandleFunc: () => {
      setLoading(false);
      enqueueSnackbar("Failed to load history", {
        variant: "error",
        tag: "FailedToLoadHistory",
      });
    },
  });

  useEffect(() => {
    if (credentialId && !history && historyOpen) {
      executeHistoryQuery();
    }
  }, [credentialId, historyOpen])

  return <Accordion
    elevation={0}
    square
    expanded={historyOpen}
    onChange={() => setHistoryOpen(!historyOpen)}
  >
    <AccordionSummary
      aria-controls="panel1d-content"
      id="panel1d-header"
      expandIcon={<ExpandMoreIcon className={clsx("expand-button")}/>}
      className={clsx([classes.historyAccordian])}
    >
      <Typography variant="h6" className={clsx([classes.historyTitle, "history-title"])}>
        History
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      {loading ?
        <Container>
          <Skeleton className={classes.skeleton} variant="rect" height="2rem" animation="wave"/>
          <Skeleton className={classes.skeleton} variant="rect" height="2rem" animation="wave"/>
          <Skeleton className={classes.skeleton} variant="rect" height="2rem" animation="wave"/>
        </Container>
        :
        <table className={clsx([classes.historyTable, "a1-responsive"])}>
          <thead className={clsx([classes.thHeader])}>
          <tr>
            <th className={clsx([classes.resultHeader])}>Location</th>
            <th className={clsx([classes.activityHeader])}>Activity</th>
          </tr>
          </thead>
          <tbody>
          {history?.map((activity, index) => (
            <tr key={activity.activityDate}>
              <td
                data-column="Location"
								className={clsx([classes.nameColumn])}
								data-testid="activity-location"
              >
                <p className={clsx([classes.location])}> {"Valet Handheld"} </p>
                <p
                  className={clsx([classes.time])}>{moment.utc(activity.activityDate).tz(timeZone).format("MM/DD/YYYY h:mm A")}</p>
              </td>
              <td data-column="Activity" className={clsx([classes.column, classes.activityColumn])}>
                <SquareChip
                  size="small"
                  text={activity.activityDescription}
                  mode={{
                    "Valet Arrival": "green",
                    "Valet Request": "red",
                  }[activity.activityDescription] || "info"}
									data-testid="activity-chip"
                />
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      }
    </AccordionDetails>
  </Accordion>;
}

HistoryComponent.defaultProps = {
	credentialId: 0
};
HistoryComponent.propTypes = {
	credentialId: PropTypes.number
};

export default HistoryComponent;
