import React, { useEffect, useState, useCallback } from "react";
import clsx from "clsx";
import { Grid, Divider } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import Skeleton from "@material-ui/lab/Skeleton";

import ValetInventoryTable from "./ValetInventoryTable/ValetInventoryTable";
import ValetInventorySearch from "./ValetInventorySearch/ValetInventorySearch";
import Title from "../Title";

import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import useCurrentFacility from "../../hooks/useCurrentFacility";
import useCancellationToken from "../../hooks/useCancellationToken";

import apiClient from "../../auth/apiClient";
import InventoryService from "../../services/InventoryService";

import { useStyles } from "./ValetInventory.styles";

const ValetInventory = () => {
  const classes = useStyles();
  const pageSize = 10;

  const inventoryService = new InventoryService(apiClient);
  const enqueueSnackbar = useEnqueueSnackbar();
  const { facilityID: entityID } = useCurrentFacility();

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [results, setResults] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const executeSearchQuery = useCancellationToken({
    func: async ({ cancelToken }) => {
      setLoading(true);
      try {
        const offset = (currentPage - 1) * pageSize;
        const res = await inventoryService.getValetInventoryData(entityID, offset, pageSize, cancelToken);
        setResults(res.data.collection || []);
        setTotalCount(res.data.totalCount || 0);
      } catch {
        enqueueSnackbar("Failed to load Data", { variant: "error", tag: "FailedToLoadData" });
      } finally {
        setLoading(false);
      }
    },
  }).execute;

  const onSearch = useCallback((searchState) => {
    setCurrentPage(1);
    executeSearchQuery();
  }, []);

  useEffect(() => {
    executeSearchQuery();
  }, [entityID, currentPage]);

  return (
    <Grid container className={clsx(classes.root)}>
      <Grid item xs={12} className={clsx(classes.header)}>
        <Title className={classes.title}>Current Inventory</Title>
      </Grid>
      <ValetInventorySearch onSearch={onSearch} loading={loading} />

      <Grid container className={clsx(classes.infoContainer)}>
        <Grid item xs={12}>
          {loading ? (
            <div className={clsx(classes.loadingWrapper)}>
              {[...Array(3)].map((_, index) => (
                <Skeleton key={index} className={classes.skeleton} animation="wave" />
              ))}
            </div>
          ) : (
            <>
              <Divider className={classes.resultsDivider} />
              {totalCount === 0 && (
                <div className={clsx(["animate__animated", "animate__flipInX", "results", "not-found", classes.noResults])}>
                  <span className={clsx(["no-results-text"])}>No results found</span>
                </div>
              )}
            </>
          )}
        </Grid>
      </Grid>

      <Grid container item spacing={2} xs={12} className={clsx(classes.tableContainer)}>
        {!loading && totalCount > 0 && (
          <>
            <Pagination
              className={clsx(classes.pagination)}
              count={Math.ceil(totalCount / pageSize)}
              onChange={(_, value) => setCurrentPage(value)}
              page={currentPage}
              shape="rounded"
              color="primary"
            />
            <ValetInventoryTable results={results} />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default ValetInventory;
